






import { Vue, Component, Prop } from "vue-property-decorator";
import NewApplication from "@/components/Dashboard/NewApplication.vue";

@Component({
  components: {
    NewApplication
  }
})
export default class NewApplicationView extends Vue {
  private mounted() {}
}
