











import { Vue, Component, Prop } from "vue-property-decorator";
import ApplicationsList from "@/components/Dashboard/ApplicationsList.vue";

@Component({
  components: {
    ApplicationsList
  }
})
export default class ApplicationsListView extends Vue {
  private mounted() {}
}
